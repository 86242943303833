import type { FC, ComponentType } from 'react';
import React, { useContext, useCallback, useRef } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { expValEquals } from '@confluence/feature-experiments';
import { LivePagePreloader } from '@confluence/hover-preloader/entry-points/LivePagePreloader';

import { PageCardContext } from '../PageCardContext';

export type CardProps = {
	isMenuOpen: boolean;
	customStyles?: object;
	isFolderHoverCard?: boolean;
};
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Card = styled.div<CardProps>(
	{
		position: 'relative',
		backgroundColor: token('elevation.surface.raised'),
		border: `1px solid ${token('color.border')}`,
		borderRadius: token('border.radius.200'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		'--show-action': ({ isMenuOpen }) => (isMenuOpen ? 1 : 0),
	},
	// folder hover cards are not interactive
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isFolderHoverCard }) =>
		!isFolderHoverCard && {
			'&:hover,&:active,&:focus-within': {
				cursor: 'pointer',
				'--show-action': 1,
				boxShadow: token('elevation.shadow.raised'),
			},
		},
);

type PageCardContainerProps = {
	StyledCard?: ComponentType<CardProps>;
	children?: React.ReactNode;
	isHoverPageCard?: boolean;
	isTriggerCard?: boolean;
};

export const PageCardContainer: FC<PageCardContainerProps> = ({
	StyledCard = Card,
	children,
	isHoverPageCard,
	isTriggerCard,
}) => {
	const { isMenuOpen, setIsViewed, type, subType, status } = useContext(PageCardContext);
	const styledCardRef = useRef<HTMLDivElement>(null);
	const handleMouseEnter = useCallback(() => {
		setIsViewed(true);

		if (
			styledCardRef.current &&
			isTriggerCard &&
			expValEquals('confluence_related_pages_gray_hover_cards', 'cohort', 'treatment')
		) {
			styledCardRef.current.style.backgroundColor = token('color.background.neutral');
		}
	}, [isTriggerCard, setIsViewed]);

	const handleMouseLeave = useCallback(() => {
		if (styledCardRef.current) {
			styledCardRef.current.style.backgroundColor = token('color.text.inverse');
		}
	}, []);

	const isFolderHoverCard = isHoverPageCard && type === 'folder';
	const isLivePage = subType === 'live' && status !== 'archived';

	return (
		<StyledCard
			ref={styledCardRef}
			isMenuOpen={isMenuOpen}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			onFocus={handleMouseEnter}
			isFolderHoverCard={isFolderHoverCard}
			// prevent clicking on the card from closing it -- folder hover cards are not interactive
			onClick={(e) => isFolderHoverCard && e.stopPropagation()}
			data-testid="page-card-card-container"
		>
			<LivePagePreloader isLivePage={isLivePage}>{children}</LivePagePreloader>
		</StyledCard>
	);
};
