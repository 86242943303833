import React, { useContext } from 'react';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { useHeightRef } from '../hooks/useHeightRef';
import { PageCardContext } from '../PageCardContext';
import { PageCardsContext } from '../PageCardsContext';

const EXCERPT_LINE_HEIGHT = 14;
const DEFAULT_NUMBER_OF_LINES = 3;
const DEFAULT_EXCERPT_HEIGHT = EXCERPT_LINE_HEIGHT * DEFAULT_NUMBER_OF_LINES;

type ExcerptContainerProps = {
	outerExcerptHeight: number;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExcerptContainer = styled.div({
	flex: '2 0 auto',
	wordWrap: 'break-word',
	marginTop: token('space.200'),
	font: token('font.body.UNSAFE_small'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexibleText = styled.div<ExcerptContainerProps>({
	display: '-webkit-box',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	WebkitLineClamp: ({ outerExcerptHeight }) => Math.floor(outerExcerptHeight / EXCERPT_LINE_HEIGHT),
	WebkitBoxOrient: 'vertical',
});

export const Excerpt = () => {
	const { width } = useContext(PageCardsContext);
	const { id, excerpt } = useContext(PageCardContext);

	const { ref: outerExcerptRef, height: outerExcerptHeight } = useHeightRef(DEFAULT_EXCERPT_HEIGHT);

	return excerpt ? (
		<ExcerptContainer key={`${id}-${width}`} ref={outerExcerptRef}>
			<FlexibleText outerExcerptHeight={outerExcerptHeight}>{excerpt}</FlexibleText>
		</ExcerptContainer>
	) : null;
};
