import type { FC } from 'react';
import React, { useContext, useCallback } from 'react';
import { styled, css } from '@compiled/react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { START_TOUCH } from '@confluence/navdex';
import { ProfileLink } from '@confluence/profile';

import { PageCardContext } from '../PageCardContext';
import { usePageCardAnalyticsEvents } from '../hooks';
import { isSpacePageCard } from '../transformers';
import type {
	CreatedBy as CreatedByType,
	OwnedBy as OwnedByType,
	Space as SpaceType,
} from '../card-types';

const i18n = defineMessages({
	createdBy: {
		id: 'page-card.created.by',
		defaultMessage: 'Created by {creator}',
		description: 'Who created the page the page card represents.',
	},
	creatorProfilePictureAlt: {
		id: 'page-card.creator.image.label',
		defaultMessage: 'Profile picture',
		description: 'Alt text for the profile picture of the creator of the page',
	},
	ownedBy: {
		id: 'page-card.owned.by',
		defaultMessage: 'Owned by {owner}',
		description: 'Who owns the page the page card represents.',
	},
	ownerProfilePictureAlt: {
		id: 'page-card.owner.image.label',
		defaultMessage: 'Profile picture',
		description: 'Alt text for the profile picture of the owner of the page',
	},
});

const linkStyle = css({
	position: 'relative',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	a: {
		color: 'var(--secondary-text-color)',
		fontWeight: token('font.weight.regular'),
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		'&:active, &:visited, &:hover, &:focus': {
			color: 'var(--secondary-text-color)',
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreatorContainer = styled.div<{ compact?: boolean }>(
	{
		zIndex: 0,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		display: 'flex',
		alignItems: 'center',
		font: token('font.body.UNSAFE_small'),
		color: token('color.text.subtle'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.compact
			? `
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
      }
    `
			: ``,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	linkStyle,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CreatorImage = styled.img<{ src?: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	src: `url(${(props) => props.src})`,
	width: '18px',
	borderRadius: '50%',
	marginRight: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OwnerContainer = styled.div<{ compact?: boolean }>(
	{
		zIndex: 0,
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		display: 'flex',
		alignItems: 'center',
		font: token('font.body.UNSAFE_small'),
		color: token('color.text.subtle'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.compact
			? `
      span {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis
      }
    `
			: ``,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	linkStyle,
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OwnerImage = styled.img<{ src?: string }>({
	width: '18px',
	borderRadius: '50%',
	marginRight: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	src: `url(${(props) => props.src})`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpaceLink = styled.div<{ compact?: boolean; endOfPage?: boolean }>(
	{
		zIndex: 1,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		font: (props) => (props.endOfPage ? token('font.body.small') : token('font.body.UNSAFE_small')),
		color: token('color.text.subtle'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		a: {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
			marginTop: (props) => (props.compact ? 'unset' : '4px'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	linkStyle,
);

const Creator: FC<CreatedByType> = ({ profilePicture, displayName, accountId }) => {
	const { appearance } = useContext(PageCardContext);
	const intl = useIntl();
	const compact = appearance === 'compact-list';
	return (
		<CreatorContainer compact={compact}>
			<CreatorImage src={profilePicture} alt={intl.formatMessage(i18n.creatorProfilePictureAlt)} />
			<FormattedMessage
				{...i18n.createdBy}
				values={{
					creator: (
						<ProfileLink userId={accountId} fullName={displayName} referralSource="pageCard" />
					),
				}}
			/>
		</CreatorContainer>
	);
};

const Owner: FC<OwnedByType> = ({ profilePicture, displayName, accountId }) => {
	const { appearance } = useContext(PageCardContext);
	const intl = useIntl();
	const compact = appearance === 'compact-list';
	return (
		<OwnerContainer compact={compact}>
			<OwnerImage src={profilePicture} alt={intl.formatMessage(i18n.ownerProfilePictureAlt)} />
			<FormattedMessage
				{...i18n.ownedBy}
				values={{
					owner: (
						<ProfileLink userId={accountId} fullName={displayName} referralSource="pageCard" />
					),
				}}
			/>
		</OwnerContainer>
	);
};

const Space: FC<SpaceType> = ({ name, spaceKey }) => {
	const context = useContext(PageCardContext);
	const { appearance } = context;
	const compact = appearance === 'compact-list';
	const endOfPage = appearance === 'end-of-page';
	const { createAnalyticsEvent } = usePageCardAnalyticsEvents();
	const handleClick = useCallback(() => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: isSpacePageCard(context) ? 'spacePageCardSpace' : 'globalPageCardSpace',
				attributes: {
					navdexPointType: START_TOUCH,
					appearance,
				},
			},
		}).fire();
	}, [createAnalyticsEvent, appearance, context]);
	return (
		<SpaceLink compact={compact} endOfPage={endOfPage}>
			<a href={SPACE_OVERVIEW.toUrl({ spaceKey })} onClick={handleClick}>
				{name}
			</a>
		</SpaceLink>
	);
};

export const Subtitle: FC = () => {
	const data = useContext(PageCardContext);

	if (isSpacePageCard(data)) {
		// check if page ownership is enabled and ownedBy
		// field is not null to account for anonymous users
		// blogs only use createdBy since they intentially don't allow owners to be changed
		return data?.ownedBy && data?.type !== 'blogpost' ? (
			<Owner {...data.ownedBy} />
		) : (
			<Creator {...data.createdBy} />
		);
	} else {
		return <Space {...data.space} />;
	}
};
