import type { FC } from 'react';
import React, { useContext } from 'react';
import { css, cssMap } from '@compiled/react';

import { token } from '@atlaskit/tokens';

import { useIsNav4Enabled } from '@confluence/nav4-enabled';

import { PageCardContext } from '../PageCardContext';

type SingleLineProp = { singleLine?: boolean };

const baseStyle = css({
	color: token('color.text'),
	textDecoration: 'none',
	outline: 'none',
	'&:active, &:visited, &:hover, &:focus': {
		color: token('color.text'),
		textDecoration: 'none',
	},
});

const defaultNav3Style = css({
	font: token('font.heading.medium'),
	fontWeight: token('font.weight.regular'),
});

const defaultStyle = css({
	font: token('font.heading.small'),
});

const singleLineStyle = css({
	display: 'block',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
});

const appearanceTitleStylesNav3 = cssMap({
	grid: {},
	list: {},
	'compact-list': {
		font: token('font.body.large'),
		fontWeight: token('font.weight.medium'),
	},
	'end-of-page': {
		font: token('font.heading.xxsmall'),
	},
});

const appearanceTitleStyles = cssMap({
	grid: {
		marginBottom: token('space.050'),
	},
	list: {
		fontWeight: token('font.weight.regular'),
		font: token('font.body'),
	},
	'compact-list': {},
	'end-of-page': {
		font: token('font.heading.xxsmall'),
	},
});

export const Title: FC<SingleLineProp> = ({ singleLine }) => {
	const {
		id,
		title: { text },
		appearance,
	} = useContext(PageCardContext);
	const isNav4Enabled = useIsNav4Enabled();

	return (
		<div
			id={`content-title-${id}`}
			role="heading"
			aria-level={2}
			css={[
				baseStyle,
				isNav4Enabled ? defaultStyle : defaultNav3Style,
				isNav4Enabled ? appearanceTitleStyles[appearance] : appearanceTitleStylesNav3[appearance],
				singleLine && singleLineStyle,
			]}
		>
			{text}
		</div>
	);
};
