import type { FC } from 'react';
import React, { useContext } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { css } from '@compiled/react';

import Lozenge from '@atlaskit/lozenge';
import { token } from '@atlaskit/tokens';

import { PageCardContext } from '../PageCardContext';

const i18n = defineMessages({
	unpublished: {
		id: 'page-card.lozenge.unpublished',
		defaultMessage: 'unpublished changes',
		description: 'When you last edited the page.',
	},
	draft: {
		id: 'page-card.lozenge.draft',
		defaultMessage: 'draft',
		description: 'When you last edited the page.',
	},
});

const columnSpacingStyles = css({
	marginTop: token('space.200'),
});

type StatusLozengeProps = {
	isInColumn?: boolean;
};

export const StatusLozenge: FC<StatusLozengeProps> = ({ isInColumn }) => {
	const { status } = useContext(PageCardContext);

	const isValidStatus = status && !!i18n[status];
	return isValidStatus ? ( // TODO: css prop is still typed for emotion
		<div css={isInColumn && (columnSpacingStyles as any)}>
			<Lozenge appearance="default">
				<FormattedMessage {...i18n[status]} />
			</Lozenge>
		</div>
	) : null;
};
